// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Q4nohhBj1Gau0oQ40YmT {\n    display: inline-block !important;\n}\n\n.cS_BSK34Mk1shytfi4s_ {\n    display: flex;\n    min-width: fit-content;\n}\n", "",{"version":3,"sources":["webpack://./src/views/components/aggregationbuilder/pivottypes/AutoTimeHistogramPivot.css"],"names":[],"mappings":"AAAA;IACI,gCAAgC;AACpC;;AAEA;IACI,aAAa;IACb,sBAAsB;AAC1B","sourcesContent":[":local(.helpBlock) {\n    display: inline-block !important;\n}\n\n:local(.alignSliderWithLabels) {\n    display: flex;\n    min-width: fit-content;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"helpBlock": "Q4nohhBj1Gau0oQ40YmT",
	"alignSliderWithLabels": "cS_BSK34Mk1shytfi4s_"
};
export default ___CSS_LOADER_EXPORT___;
