// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UplwMCJC0BMZsTrnJoUg {\n    min-width: fit-content;\n}\n\n", "",{"version":3,"sources":["webpack://./src/views/components/aggregationbuilder/pivottypes/TimeUnitTimeHistogramPivot.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;AAC1B","sourcesContent":[":local(.useFullWidth) {\n    min-width: fit-content;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"useFullWidth": "UplwMCJC0BMZsTrnJoUg"
};
export default ___CSS_LOADER_EXPORT___;
