// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dNsvLTLP8qoXTT4hoUlc {\n    margin-top: 0px !important;\n}\n", "",{"version":3,"sources":["webpack://./src/views/components/aggregationbuilder/pivottypes/TimeHistogramPivot.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;AAC9B","sourcesContent":[":local(.automaticCheckbox) {\n    margin-top: 0px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"automaticCheckbox": "dNsvLTLP8qoXTT4hoUlc"
};
export default ___CSS_LOADER_EXPORT___;
